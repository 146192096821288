<template>
	<div>
		<user-role-list-add-new :is-add-new-user-role-sidebar-active.sync="isAddNewUserRoleSidebarActive"
			@refetch-data="refetchData" />
		<user-role-list-edit :is-edit-user-role-sidebar-active.sync="isEditUserRoleSidebarActive"
			:editUserRole="editUserRole" @refetch-data="refetchData" />
		<b-card no-body class="mb-0">
			<div class="m-2">
				<b-row>
					<b-col cols="12" md="6">
						<label>Show</label>
						<v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions" :clearable="false"
							class="per-page-selector d-inline-block mxz-50 ml-1" />
					</b-col>
					<b-col cols="12" md="6">
						<b-row>
							<b-col class="mb-md-0 mb-2" cols="12 " md="4">
								<v-select v-if="teamOptions" v-model="teamFilter" :options="teamOptions" placeholder="Select Team"
									:clearable="true" :reduce="(option) => option.key" label="label" class="w-100" />
							</b-col>
							<b-col cols="12 " md="4" class="mb-md-0 mb-2">
								<b-form-input v-model="searchQuery" class="d-inline-block mr-1"
									placeholder="Search..." />
							</b-col>
							<b-col cols="12 " md="4" v-if="$can('read', 'userRoleCreate')" class="mb-md-0 mb-2">
								<b-button class="w-100" variant="primary" @click="isAddNewUserRoleSidebarActive = true">
									<span class="text-nowrap">New User Role</span>
								</b-button>
							</b-col>

						</b-row>
					</b-col>
				</b-row>
			</div>
			<b-table ref="refUserRoleListTable" class="position-relative" :items="fetchUserRoles" responsive striped
				:fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
				empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
				<template #table-busy>
					<div class="text-center my-2">
						<b-spinner class="d-block mx-auto mb-50"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				<!-- Column: id -->
				<template #cell(id)="data">
					<span class="align-text-top text-capitalize">{{ data.item.id }}</span>
				</template>
				<template #cell(name)="data">
					<span class="align-text-top text-capitalize">{{ data.item.name }}</span>
				</template>
				<!-- Column: Perms -->
				<template #cell(permissions)="data">
					<span class="align-text-top text-capitalize" v-if="data.item.name ==='admin' ">
						<b-badge pill class="text-capitalize mr-50" variant="light-success">All Permissions</b-badge>
					</span>
					<b-badge pill class="text-capitalize mr-50" v-for="perm in data.item.permissions" :key="perm.id"
						v-else>
						{{ perm.name }}
					</b-badge>
				</template>
				<!-- Column: Users -->
				<template #cell(users)="data" class="d-flex">
					<b-avatar-group size="24px">
						<b-avatar v-for="user in data.item.users" :key="user.id" class="pull-up" :src="user.image"
							:text="avatarText(user.name)" v-b-tooltip.hover.top="user.name" />
					</b-avatar-group>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<b-link v-if="$can('read', 'userRoleEdit')" class="mr-1" v-b-tooltip.hover.top="'Edit User'"
						@click="fncEditUserSidebar(data.item.id)">
						<feather-icon icon="EditIcon" size="18" />
					</b-link>

					<b-link v-if="$can('read', 'userRoleDelete')" class="mr-1" v-b-tooltip.hover.top="'Delete User'"
						@click="confirmDelete(data.item.id)">
						<feather-icon icon="TrashIcon" size="18" />
					</b-link>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col cols="12" sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
							entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">
						<b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number
							last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {ref} from '@vue/composition-api'
import {avatarText} from '@core/utils/filter'
import useUserRoleApi from '@/composables/useUserRoleApi'
import UserRoleListAddNew from './UserRoleListAddNew.vue'
import UserRoleListEdit from './UserRoleListEdit.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	VBTooltip,
	BAvatarGroup,
	BSpinner
} from 'bootstrap-vue'
export default {
	components: {
		BSpinner,
		UserRoleListEdit,
		UserRoleListAddNew,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BAvatarGroup,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		ToastificationContent,
		vSelect,
	},
	directives: {
		'b-tooltip': VBTooltip,
		Ripple,
	},
	setup() {
		const isAddNewUserRoleSidebarActive = ref(false)
		const isEditUserRoleSidebarActive = ref(false)
		const editUserRole = ref(null)
		const {
			fetchUserRoles,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserRoleListTable,
			refetchData,
			fetchTeams,
			teamFilter,
			teamOptions
		} = useUserRoleApi()
		return {
			isAddNewUserRoleSidebarActive,
			isEditUserRoleSidebarActive,
			fetchUserRoles,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserRoleListTable,
			refetchData,
			avatarText,
			editUserRole,
			fetchTeams,
			teamFilter,
			teamOptions
		}
	},
	mounted() {
		this.fetchTeams()
	},
	methods: {
		fncEditUserSidebar(id) {
			this.isEditUserRoleSidebarActive = true;
			this.$store.dispatch('userRoleStore/fetchRole', {
				id: id
			}).then((response) => {
				const {
					data
				} = response.data
				this.editUserRole = data
				this.editUserRole.permissions = data.permissions.map(item => ({
					value: item.name,
					label: item.name
				}))
				this.editUserRole.users = data.users.map(item => ({
					value: item.id,
					label: item.name
				}))
			})
		},
		confirmDelete(id) {
			this.$swal.fire({
				title: 'Are you sure?',
				text: "Are you sure you want to delete the role?",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete!',
				customClass: {
					confirmButton: 'btn btn-primary mr-1',
					cancelButton: 'btn btn-outline-primary ms-1'
				},
				buttonsStyling: false
			}).then(result => {
				if (result.value) {
					this.$store.dispatch('userRoleStore/deleteRole', {
						id: id
					}).then(() => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Successfully deleted!',
								icon: 'CheckIcon',
								variant: 'success',
							},
						})
					}).then(() => {
						this.refetchData()
					})
				}
			}).catch((error) => {
				console.log(error);
				this.refetchData()
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
