import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUserRoleApi() {
	const toast = useToast()
	const refUserRoleListTable = ref(null)
	const perPage = ref(localStorage.getItem('userListRolePerPage')??10)
	const totalUsers = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10,15, 20, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const permissionOptions = ref([])
	const userOptions = ref([])
	const teamFilter = ref(null)
	const teamOptions = ref(null)
	const tableColumns = [
		{ key: 'id', sortable: true },
		{ key: 'name', sortable: true },
		{ key: 'permissions', sortable: false },
		{ key: 'users'},
		{ key: 'actions' },
	]
	const blankUserData = {
		name: '',
		permissions: [],
		users:'',
	}
	const userRoleData = ref(JSON.parse(JSON.stringify(blankUserData)))
	const resetuserRoleData = () => {
		userRoleData.value = JSON.parse(JSON.stringify(blankUserData))
	}
	const dataMeta = computed(() => {
		const localItemsCount = refUserRoleListTable.value ? refUserRoleListTable.value.localItems.length : 0
		return {
		from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
		to: perPage.value * (currentPage.value - 1) + localItemsCount,
		of: totalUsers.value,
		}
	})
	const refetchData = () => {
		localStorage.setItem('userListRolePerPage',perPage.value)
		refUserRoleListTable.value.refresh()
	}
	watch([currentPage, perPage, searchQuery,teamFilter], () => {
		refetchData()
	})
	const fetchUserRoles = (ctx, callback) => {
		let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
		store
		.dispatch('userRoleStore/fetchUserRoles', {
			sort: sort,
			q: searchQuery.value,
			perPage: perPage.value,
			page: currentPage.value,
			sortBy: sortBy.value,
			team:teamFilter.value,
			pagination:true

		})
		.then(response => {
			const { data, total } = response.data
			callback(data)
			totalUsers.value = response.data.pagination.total
		})
		.catch(() => {
			toast({
			component: ToastificationContent,
			props: {
				title: 'Error',
				icon: 'AlertTriangleIcon',
				variant: 'danger',
				text:'Oops! Something went wrong!',
			},
			})
		})
	}
	const fetchPermission = (ctx, callback) => {
		store.dispatch('userRolePermissionStore/fetchPermissions',{})
		.then(response=>{
		const permFnc = response.data
		permissionOptions.value = permFnc.data.map(item=>({value:item.name,label:item.name}))
		}).catch(error =>{console.log(error)})
	}
	const fetchUsers= (ctx, callback) => {
		store.dispatch('userStore/fetchUsers')
		.then(response=>{
		const userFnc = response.data
		userOptions.value = userFnc.data.map(item=>({value:item.id,label:item.name}))
		}).catch(error =>{console.log(error)})
	}
  	// ------------------------------------------------
	// Get Teams
	// ------------------------------------------------
	const fetchTeams = (ctx, callback) => {
		store
		.dispatch('userStore/fetchTeams', ).then(response => {
			const mapFnc =   response.data
			teamOptions.value = mapFnc.data.map(item => ({key: item.id, label: item.name }))
		}).catch(error => {
			console.log(error);
			toast({
			component: ToastificationContent,
			props: {
				title: 'Error',
				icon: 'AlertTriangleIcon',
				variant: 'danger',
				text: 'Oops! Something went wrong!',
			},
			})
		})
	}
  return {
    fetchUserRoles,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserRoleListTable,
    refetchData,
    toast,
    blankUserData,
    permissionOptions,
    userRoleData,
    resetuserRoleData,
    fetchPermission,
    fetchUsers,
    userOptions,
	fetchTeams,
	teamFilter,
	teamOptions
  }
}
