<<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserRoleSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-role-sidebar-active', val)">
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          New User Role
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(addRoleUser)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext" name="Role Name" rules="required">
            <b-form-group label="Role Name" label-for="role-name">
              <b-form-input id="role-name" v-model="userRoleData.name" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Dev" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="Permission" rules="required">
            <b-form-group label="Permissions" label-for="permission" :state="errors.length > 0 ? false:null">
              <v-select id="permission" v-model="userRoleData.permissions" :options="permissionOptions" label="label" 
                placeholder="Select Permissions" class="w-100" multiple>
              </v-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="User" rules="required">
            <b-form-group label="Users" label-for="user" :state="errors.length > 0 ? false:null">
              <v-select id="user" v-model="userRoleData.users" :options="userOptions" label="label"
                placeholder="Select Users" class="w-100" multiple>
              </v-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
  </template>>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useUserRoleApi from '@/composables/useUserRoleApi'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserRoleSidebarActive',
    event: 'update:is-add-new-user-role-sidebar-active',
  },
  props: {
    isAddNewUserRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
   
    const {
      blankUserData,
      permissionOptions,
      userRoleData,
      resetuserRoleData,
      fetchPermission,
      fetchUsers,
      userOptions,
      toast,
    } = useUserRoleApi()

    const addRoleUser = () => {
      store.dispatch('userRoleStore/addRole', {
        name: userRoleData.value.name,
        permission:userRoleData.value.permissions,
        user:userRoleData.value.users,
      }).then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully added!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-add-new-user-role-sidebar-active', false)
      }).catch((error)=>{
        console.log(error)
      })
    }
  
   const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserRoleData)
    return {
      userRoleData,
      addRoleUser,
      refFormObserver,
      getValidationState,
      resetForm,
      permissionOptions,
      fetchPermission,
      userOptions,
      fetchUsers,
      blankUserData,
    }
  },
  mounted(){
    this.fetchPermission()
    this.fetchUsers()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
